angular.module('app')
    .directive('popover', function($timeout) {
      return {
        restrict: 'A',
        replace: false,
        transclude: false,
        link: {
          post: function($scope, element, attrs) {
            $timeout(function() {
              element.popover({
                trigger: angular.isDefined(attrs.trigger) && '' !== attrs.trigger ? attrs.trigger : 'hover'
              });
            });

            var isTouchDevice = function() {
              return 'ontouchstart' in window || // works on most browsers
                   'onmsgesturechange' in window; // works on ie10
            };

            $scope.$on('$destroy', function() {
              element.popover('destroy');
            });

            element.bind('show.bs.popover', function() {
              if (isTouchDevice()) {
                element.click();
              }
            });
          }
        }
      };
    });
