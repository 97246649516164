// Declaring feature components
var layoutName = 'management';

angular.module('app')
  .config(function(ezLayoutProvider) {
    ezLayoutProvider.register(layoutName, {templateUrl: "client/layouts/management/management.html"});
  })
  .controller('ManagementController', function() {
    var self = this;

    self.currentLink = '';
    self.changeLink = function(newLink) {
      self.currentLink = newLink;
    };
    self.isCurrentLink = function(link) {
      return self.currentLink === link;
    };
  });
