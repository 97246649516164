/**
 * Module file for the feature.
 * Defines links to less and template files, dependencies, controller, feature-specific directives and services.
 */
angular.module('app')
    .directive('inputFileModel', [function() {
      return {
        scope: {
          inputFileModel: '='
        },
        link: function(scope, element) {
          element.bind('change', function(changeEvent) {
            scope.inputFileModel = changeEvent.target.files[0];
          });
        }
      };
    }])
;
