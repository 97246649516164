/**
 * This is an example service that fetches an "hello world" text from the REST server.
 *
 * You can find the associated REST stub in /tasks/rest/example.js.
 */
const _environment = window._env;
angular.module('app')
    .factory('InternalReport', function($resource) {
      return $resource(_environment.baseUrl + '/rest/internal-reporting', {}, {
        'delete': {url: _environment.baseUrl + '/rest/admin/internal-reporting/:id', params: {id: '@id'}, method: 'POST'},
        'findForAdmin': {url: _environment.baseUrl + '/rest/admin/internal-reporting', method: 'GET', isArray: true}
      });
    })
    .service('reportService', function() {
      this.getReportGroupByCategory = function(reports) {
        var categories = [];
        angular.forEach(reports, function(report) {
          var catIndex = -1;
          for (var i = 0; i < categories.length; i++) {
            if (categories[i].id === report.category.parent.id) {
              catIndex = i;
              break;
            }
          }
          if (catIndex == -1) {
            catIndex = categories.push(angular.copy(report.category.parent)) - 1;
          }
          var subcategory = {
            id: report.category.id,
            name: report.category.name,
            reports: []
          };
          if (angular.isUndefined(categories[catIndex].subcategories)) {
            categories[catIndex].subcategories = [];
          }
          var subCatIndex = -1;
          for (var j = 0; j < categories[catIndex].subcategories.length; j++) {
            if (categories[catIndex].subcategories[j].id === subcategory.id) {
              subCatIndex = j;
              break;
            }
          }
          if (subCatIndex == -1) {
            subCatIndex = categories[catIndex].subcategories.push(subcategory) - 1;
          }
          var reportIndex = -1;
          for (var k = 0; k < categories[catIndex].subcategories[subCatIndex].reports.length; k++) {
            if (categories[catIndex].subcategories[subCatIndex].reports[k].id === report.id) {
              reportIndex = k;
              break;
            }
          }
          if (reportIndex == -1) {
            categories[catIndex].subcategories[subCatIndex].reports.push(report);
          }
        });
        return categories;
      };
    })
;
