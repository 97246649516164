/**
 * Module file for the feature.
 * Defines links to less and template files, dependencies, controller, feature-specific directives and services.
 */
angular.module('app')
    .config(function($routeProvider) {
      $routeProvider
          .when('/manage/users', {
            templateUrl: "client/features/manage/users/users.html",
            layout: 'management'
          })
          .when('/manage/users/:categoryId', {
            templateUrl: "client/features/manage/users/users.html",
            layout: 'management'
          });
    })
    .controller('UserManagementController', function($routeParams, Category, SubCategory) {
      var self = this;
      self.routeParams = $routeParams;

      self.categories = Category.queryWritable({withUsers: true});
      self.categories.$promise.then(function() {
        if (angular.isDefined(self.routeParams.categoryId)) {
          angular.forEach(self.categories, function(category) {
            if (category.id === parseInt(self.routeParams.categoryId)) {
              self.current = category;

              self.subCategories = SubCategory.query({parentId: self.current.id, withUsers: true});
            }
          });
        }
      });

      self.registerReader = function(category, email) {
        category.$addReadAccess({username: email});
      };

      self.removeReader = function(category, email) {
        category.$removeReadAccess({username: email});
      };

      self.registerWriter = function(category, email) {
        category.$addWriteAccess({username: email});
      };

      self.removeWriter = function(category, email) {
        self.lastWriterError = false;
        category.$removeWriteAccess({username: email}).then(function() {}, function() {
          self.lastWriterError = true;
        });
      };

      self.editSubCategory = function(category) {
        self.currentSubCategory = category;
        self.subCatModal.show();
      };
    })
;
