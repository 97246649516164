function FooterComponentController($window, ezAnalytics) {
  var self = this;

  self.$onInit = function() {
    self.widget = document.querySelector('sgwt-mini-footer');
    // Ask the widget to get the user consent on Matomo.
    self.widget.getUserConsentOnMatomo()
      .then(function(consent) {
        var _paq = $window._paq || [];
        // In any case, we configure Matomo to wait the cookies consent before using any cookie.
        _paq.push(['requireCookieConsent']);

        if (consent) {
          // 👍 USER CONSENTS TO MATOMO COOKIES USAGE.
          // Allow Matomo to use cookies for 6 months (4320 hours).
          _paq.push(['rememberCookieConsentGiven', 4320]);
          // Add a custom dimension for SG Monitoring.
          _paq.push(['setCustomDimension', 10, true]);
          _paq.push(['setUserId', $window.sgwtConnect.getIdTokenClaims().sub]);
        } else {
          // 👎 USER DO NOT CONSENT TO MATOMO COOKIES USAGE.
          // Clean Matomo cookies
          _paq.push(['forgetCookieConsentGiven']);
          // Add a custom dimension for SG Monitoring.
          _paq.push(['setCustomDimension', 10, false]);
        }
        ezAnalytics.start($window._env.piwik.url, $window._env.piwik.siteId);
      });
    self.widget.addEventListener('sgwt-mini-footer--user-consent-changed', self.handleMatomoCookieConsent.bind(self));
  }

  self.handleMatomoCookieConsent = function(event) {
    self.widget.getUserConsentOnMatomo().then(function(consent) {
      const _paq = $window._paq || [];
      if (consent) {
        // 👍 USER CONSENTS TO MATOMO COOKIES USAGE.
        _paq.push(['rememberCookieConsentGiven', 4320]);
        _paq.push(['setCustomDimension', 10, true]);
      } else {
        // 👎 USER DO NOT CONSENT TO MATOMO COOKIES USAGE.
        _paq.push(['forgetCookieConsentGiven']);
        _paq.push(['setCustomDimension', 10, false]);
      }
    });
  }

  self.$onDestroy = function() {
    self.widget.removeEventListener('sgwt-mini-footer--user-consent-changed', self.handleMatomoCookieConsent);
  }
}

var FooterComponent = {
  templateUrl: './mini-footer.html',
  controller: ['$window', 'ezAnalytics', FooterComponentController]
};

angular.module('app').component('miniFooter', FooterComponent);
