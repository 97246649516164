/**
 * Module file for the feature.
 * Defines links to less and template files, dependencies, controller, feature-specific directives and services.
 */
angular.module('app')
    .directive('logout', function($http, $window) {
      return {
        restrict: 'A',
        link: function(scope, element) {
          element.bind('click', function() {
            $http({
              url: 'logout',
              method: 'POST'
            }).then(function successCallback(response) {
              if (angular.isDefined(response.data) && '' !== response.data) {
                $window.location = response.data;
              }
            });
          });
        }
      };
    })
;
