angular.module('app')
  .factory('sgConnectInterceptor', function($q) {
    return {
      request: function(config) {
        // intercept HTTP request only for the configured HTTP types
        if (window.sgwtConnect.getAuthorizationHeader()) {
          config.headers.Authorization = window.sgwtConnect.getAuthorizationHeader();
        }
        return config || $q.when(config);
      }
    };
  })
  .config(function($httpProvider) {
    $httpProvider.interceptors.push('sgConnectInterceptor');
  })
;
