const _environment = window._env;
// Start the web application, taking care of loading required modules
window.ezweb.start() //
  // Configure the different aspects of your app here.
  // We give you an example using the ezweb-auth default authentication backend and Spring Security
  .config(function(ezUsersProvider, ezPostAuthenticationBackendProvider) {
      // Always configure the backend you are going to use before
      // configuring the user service with it (if configuration of the backend is necessary).
      ezPostAuthenticationBackendProvider.configure({
        // See the file (hopefully soon the docs) to see what options are available.
        // Any option not set is kept at default value.
        rememberMeParameter: '_spring_security_remember_me',
        currentUserUri: _environment.baseUrl + "/rest/users/current"
      });
      ezUsersProvider.configure('ezPostAuthenticationBackend');
    }
  )
  .config(function(csrfProvider) {
    // optional configurations
    csrfProvider.config({
      url: _environment.baseUrl + '/rest/csrf',
      maxRetries: 3,
      csrfTokenHeader: 'X-XSRF-TOKEN',
      csrfHttpType: 'head',
      httpTypes: ['PUT', 'POST', 'DELETE', 'GET'] // CSRF token will be added only to these method types
    });
  })
  .config(function(sgwtConnectProvider) {
    sgwtConnectProvider.use(window.sgwtConnect);
  })
  .config(function($locationProvider) {
    $locationProvider.hashPrefix('');
  })
  .run(function() {
    if (window.sgwtConnect.getAuthorizationError()) {
      document.body.innerHTML = '<div class="alert alert-danger" role="alert">' +
        'Authorization error: ' + window.sgwtConnect.getAuthorizationError() +
        '</div>';
    } else if (!window.sgwtConnect.isAuthorized()) {
      window.sgwtConnect.requestAuthorization();
    }
  });
