var CheckPhoneComponent = {
  bindings: {
    api: '=',
    description: '<'
  },
  templateUrl: './confirm-action-modal.html',
  controller: ['$q', function($q) {
    angular.extend(this, {$q: $q, modalApi: {}});

    this.$postLink = function() {
      if (!this.api) {
        this.api = {
          open: this.open.bind(this)
        };
      } else {
        angular.extend(this.api, {
          open: this.open.bind(this)
        });
      }
    }.bind(this);

    this.open = function() {
      this.deferred = this.$q.defer();
      this.modalApi.show();
      return this.deferred.promise;
    }.bind(this);

    this.confirm = function() {
      this.deferred.resolve();
      this.modalApi.hide();
    }.bind(this);

    this.cancel = function() {
      this.deferred.reject();
      this.modalApi.hide();
    }.bind(this);
  }]
};

angular.module('app').component('confirmActionModal', CheckPhoneComponent);
