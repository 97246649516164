/**
 * This is an example service that fetches an "hello world" text from the REST server.
 *
 * You can find the associated REST stub in /tasks/rest/example.js.
 */
const _environment = window._env;

angular.module('app')
    .factory('Category', function($resource) {
      return $resource(_environment.baseUrl + '/rest/admin/category/:id', {id: '@id'}, {
        queryWritable: {url: _environment.baseUrl + '/rest/admin/category/writable', method: 'GET', isArray: true},
        addWriteAccess: {url: _environment.baseUrl + '/rest/admin/category/:id/addWriteAccess', method: 'POST', isArray: false},
        addReadAccess: {url: _environment.baseUrl + '/rest/admin/category/:id/addReadAccess', method: 'POST', isArray: false},
        removeWriteAccess: {url: _environment.baseUrl + '/rest/admin/category/:id/removeWriteAccess', method: 'POST', isArray: false},
        removeReadAccess: {url: _environment.baseUrl + '/rest/admin/category/:id/removeReadAccess', method: 'POST', isArray: false}
      });
    })
;
