angular.module('app')
    .service('fileUploader', function($rootScope, csrfService) {
      this.prgressEvtName = 'uploadProgress';
      this.failedEvtName = 'uploadFailed';
      this.completeEvtName = 'uploadCompleted';

      var self = this;

      var uploadComplete = function(evt) {
        if (evt.target.status === 200) {
          $rootScope.$broadcast(self.completeEvtName, evt);
        } else {
          $rootScope.$broadcast(self.failedEvtName, evt);
        }
      };

      var uploadProgress = function(evt) {
        if (evt.lengthComputable) {
          var progress = Math.round(evt.loaded * 100 / evt.total);
          $rootScope.$broadcast(self.prgressEvtName, progress);
        }
      };

      var uploadFailed = function(evt) {
        $rootScope.$broadcast(self.failedEvtName, evt);
      };

      var uploadCanceled = function(evt) {
        console.info('upload canceled', evt);
      };

      return {
        send: function(url, params, file, prgressEvtName, failedEvtName, completeEvtName) {
          if (angular.isDefined(prgressEvtName)) {
            self.prgressEvtName = prgressEvtName;
          }
          if (angular.isDefined(failedEvtName)) {
            self.failedEvtName = failedEvtName;
          }
          if (angular.isDefined(completeEvtName)) {
            self.completeEvtName = completeEvtName;
          }
          var parameters = '?';
          angular.forEach(params, function(value, key) {
            if (value) {
              parameters += key + '=' + value + '&';
            }
          });
          var fd = new FormData();
          fd.append('file', file);

          var xhr = new XMLHttpRequest();
          xhr.upload.addEventListener('progress', uploadProgress, false);
          xhr.addEventListener('load', uploadComplete, false);
          xhr.addEventListener('error', uploadFailed, false);
          xhr.addEventListener('abort', uploadCanceled, false);

          xhr.open('POST', url + parameters, true);
          xhr.setRequestHeader(csrfService.settings.csrfTokenHeader, csrfService.token);
          xhr.setRequestHeader('Client-uuid', csrfService.settings.uuid);
          xhr.setRequestHeader('Authorization', window.sgwtConnect.getAuthorizationHeader());

          xhr.send(fd);
        }
      };
    })
;
