function watchAction(condition, $scope, $element, $transclude) {
  // This code is copy-pasted from the ngIf directive with small adaptations
  var block;
  return function() {
    if (condition() === true) {
      if (!block) {
        $transclude($scope, function(clone) {
          block = {
            clone: clone
          };
          $element.after(clone);
        });
      }
    } else {
      if (block) {
        block.clone.remove();
        block = null;
      }
    }
  };
}

angular.module('app').directive('userHasRoles', function(ezUsers) {
  return {
    restrict: 'A',
    transclude: 'element',
    priority: 10000,
    terminal: true,
    scope: false,
    link: function($scope, $element, $attrs, ctrl, $transclude) {
      // noinspection JSUnresolvedVariable
      var roleList = $attrs.userHasRoles.split(',');

      // For some reason even with deep watching the token was not being picked up
      $scope.$watch(function() {
        return ezUsers.roles;
      }, watchAction(function() {
        var hasAtLeastOneRole = false;
        angular.forEach(roleList, function(role) {
          hasAtLeastOneRole = hasAtLeastOneRole || $.inArray(role, ezUsers.roles) >= 0;
        });
        return hasAtLeastOneRole;
      }, $scope, $element, $transclude));
    }
  };
});
