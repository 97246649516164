/**
 * Module file for the feature.
 * Defines links to less and template files, dependencies, controller, feature-specific directives and services.
 */
angular.module('app')
    .directive('userRightGrid', [function() {
      return {
        templateUrl: './user-right-grid.html',
        replace: true,
        scope: {
          title: '@',
          category: '=',
          users: '=',
          addAction: '&',
          removeAction: '&'
        }
      };
    }])
;
