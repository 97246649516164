/**
 * This is an example filter.
 *
 * Although it is included in the app and can be used as-is, we recommend that you rename it
 * if you want to actually use it.
 */
angular.module('app')
  .filter('checkmark', function() {
    return function(input) {
      return input ? '\u2713' : '\u2718';
    };
  })
;
