/**
 * Module file for the feature.
 * Defines links to less and template files, dependencies, controller, feature-specific directives and services.
 */
const _environment = window._env;
angular.module('app')
    .config(function($routeProvider) {
      $routeProvider
          .when('/manage', {
            redirectTo: '/manage/reports'
          })
          .when('/manage/reports', {
            templateUrl: "client/features/manage/reports/reports.html",
            layout: 'management'
          })
          .when('/manage/reports/:reportId', {
            templateUrl: "client/features/manage/reports/reports.html",
            layout: 'management'
          });
    })
    .controller('ReportManagementController',
    function($window, $scope, $routeParams, $timeout, InternalReport, reportService,
             fileUploader, Category, SubCategory) {
      var NProgress = $window.NProgress;

      NProgress.configure({parent: '.modal-content'});

      var self = this;
      self.routeParams = $routeParams;

      self.zoomOption = [
        {label:'100%', value: 0},
        {label:'150%', value: 1},
        {label:'200%', value: 2},
        {label:'250%', value: 3}
      ];

      self.formatedReports = [];
      var fillReports = function() {
        self.reports.forEach(function(report) {
          if (report.lastViewedDate) {
            report.lastViewedDate = moment(report.lastViewedDate).toDate();
          }
        });
        self.formatedReports.length = 0;
        self.formatedReports.push.apply(self.formatedReports, reportService.getReportGroupByCategory(self.reports));
      };

      self.reports = InternalReport.findForAdmin();
      self.reports.$promise.then(function() {
        fillReports();
      });

      self.create = function() {
        self.report = {};
        self.categories = Category.queryWritable();
        self.createModal.show();
      };

      self.fetchSubcategories = function(parentId) {
        self.subcategories = SubCategory.queryWritable({parentId: parentId});
      };

      $scope.$on('uploadProgress', function(evt, progress) {
        NProgress.set((progress - 1) / 100);
      });

      $scope.$on('uploadFailed', function(evt) {
        console.error('An error occurred', evt);
        $timeout(function() {
          NProgress.done();
          self.uploading = false;
          self.uploadFailed = true;
        });
      });

      $scope.$on('uploadCompleted', function(evt, arg) {
        NProgress.done();
        delete self.report;
        var report = new InternalReport(angular.fromJson(arg.currentTarget.response));
        self.reports.push(report);
        fillReports();
        self.uploading = false;
        $timeout(function() {
          self.createModal.hide();
        }, 100);
      });

      self.setLevel = function(level) {
        self.report.level = level;
      };

      self.save = function(form) {
        if (form.$valid && self.report.level !== undefined && self.report.level <= 2) {
          $timeout(function() {
            fileUploader.send(_environment.baseUrl + '/rest/admin/internal-reporting/new',
              {name: self.report.name, category: self.report.subcategory.id, zoomLevel: self.report.zoomLevel},
              self.report.file);
          });
          self.uploading = true;
          self.uploadFailed = false;
          NProgress.start();
        } else {
          form.$setDirty();
          form.$error.required.forEach(function(input) {
            input.$setDirty();
          });
        }
      };

      self.openDeleteModal = function(report) {
        self.reportToDelete = report;
        self.deleteModal.show();
      };

      self['delete'] = function() {
        self.deleting = true;
        NProgress.start();
        self.reportToDelete.$delete().then(function() {
          var index = self.reports.indexOf(self.reportToDelete);
          if (index !== -1) {
            self.reports.splice(index, 1);
            fillReports();
          }
          self.deleting = false;
          NProgress.done();
          $timeout(function() {
            self.deleteModal.hide();
          }, 100);
        });
      };
    })
;
