/**
 * Module file for the feature.
 * Defines links to less and template files, dependencies, controller, feature-specific directives and services.
 */
angular.module('app')
  .config(function ($routeProvider) {
    $routeProvider
      .when('/manage/categories', {
        templateUrl: "client/features/manage/categories/categories.html",
        layout: 'management'
      })
      .when('/manage/categories/:categoryId', {
        templateUrl: "client/features/manage/categories/categories.html",
        layout: 'management'
      });
  })
  .controller('CategoryManagementController', function ($routeParams, $location, Category, SubCategory) {
    var self = this;
    self.routeParams = $routeParams;
    self.$location = $location;
    self.confirmActionModalApi = {};

    function computeLastViewDate(category) {
      if (category.lastViewedDate) {
        category.lastViewedDate = moment(category.lastViewedDate).toDate();
      }
    }

    self.categories = Category.query({withUsers: true});
    self.categories.$promise.then(function () {
      if (angular.isDefined(self.routeParams.categoryId)) {
        angular.forEach(self.categories, function (category) {
          computeLastViewDate(category);
          if (category.id === parseInt(self.routeParams.categoryId)) {
            self.current = category;
            SubCategory.query({parentId: self.current.id}).$promise
              .then(function(subCategories) {
                self.subCategories = subCategories.map(function(subCategory) {
                  computeLastViewDate(subCategory);
                  return subCategory;
                });
              });
          }
        });
      }
    });

    self.create = function () {
      self.category = new Category();
      self.category.usersCanWrite = [];
      self.categorySavingError = false;
      self.createModal.show();
    };

    self.registerWriter = function (email) {
      self.category.usersCanWrite.push({username: email});
    };

    self.removeWriter = function (email) {
      var indexToDel = -1;
      for (var i = 0; i < self.category.usersCanWrite.length; i++) {
        if (self.category.usersCanWrite[i].username === email) {
          indexToDel = i;
          break;
        }
      }
      if (indexToDel > -1) {
        self.category.usersCanWrite.splice(indexToDel, 1);
      }
    };

    self.save = function (category) {
      var isCreation = angular.isUndefined(category.id);
      category.$save(function (response) {
        if (isCreation) {
          self.categories.push(response);
          self.createModal.hide();
        }
      }, function () {
        self.categorySavingError = true;
      });
    };

    self.addSubcategory = function () {
      self.subcategory = new SubCategory();
      self.subCategorySavingError = false;
      self.createSubcategoryModal.show();
    };

    self.editSubcategory = function (subcategory) {
      self.subcategory = subcategory;
      self.subcategorySavedName = subcategory.name;
      self.subCategorySavingError = false;
      self.createSubcategoryModal.show();
    };

    self.saveSubcategory = function (subcategory) {
      var isCreation = angular.isUndefined(subcategory.id);
      subcategory.$save({parentId: self.current.id}, function (response) {
        if (isCreation) {
          self.subCategories.push(response);
        }
        self.createSubcategoryModal.hide();
      }, function () {
        self.subCategorySavingError = true;
      });
    };

    self.cancelSubCategoryEdition = function () {
      self.subcategory.name = self.subcategorySavedName;
      self.createSubcategoryModal.hide();
    };

    self.hasSelectedSubCategories = function (subCategories) {
      return subCategories.find(function (cat) {
        return !!cat.selected;
      });
    };

    self.selectedSubCategories = function (subCategories) {
      return subCategories.filter(function (cat) {
        return !!cat.selected;
      });
    };

    var _deleteSubCategory = function (subCategory) {
      return subCategory.$delete().then(function () {
        var indexToRemove = self.subCategories.indexOf(subCategory);
        self.subCategories.splice(indexToRemove, 1);
      });
    };

    self.deleteSubCategory = function (subCategory) {
      self.confirmActionModalApi.open()
        .then(function () {
          return _deleteSubCategory(subCategory);
        })
        .catch(function (error) {
          console.error(error);
        });
    };

    self.deleteSubCategories = function (subCategories) {
      self.confirmActionModalApi.open()
        .then(function () {
          subCategories.forEach(_deleteSubCategory);
        })
        .catch(function (error) {
          console.error(error);
        });
    };

    var _deleteCategory = function (category) {
      return category.$delete().then(function () {
        if (category.id === parseInt(self.routeParams.categoryId)) {
          self.$location.path('/manage/categories');
        } else {
          var indexToRemove = self.categories.indexOf(category);
          self.categories.splice(indexToRemove, 1);
        }
      });
    };

    self.deleteCategory = function (category) {
      self.confirmActionModalApi.open()
        .then(function () {
          return _deleteCategory(category);
        })
        .catch(function (error) {
          console.error(error);
        });
    };
  })
;
