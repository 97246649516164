const _environment = window._env;
/**
 * Module file for the feature.
 * Defines links to less and template files, dependencies, controller, feature-specific directives and services.
 */
angular.module('app')
    .config(function(sgwtConnectProvider) {
      sgwtConnectProvider.use(window.sgwtConnect);
    })
    // Configure the routes to the feature here. Each feature takes care of its own routes.
    // By convention, the route should start with your feature name ( /home/...)
    // Here we break this convention because it's the home page !
    // Note that you should take care of unsupported routes with otherwise method,
    // "default" feature such as home is a great place to do that.
    .config(function($routeProvider) {
      $routeProvider
          .when('/view', {
            templateUrl: './home.html'
          })
          .when('/view/:categoryId,:categoryName', {
            templateUrl: './home.html'
          })
          .when('/view/:categoryId,:categoryName/:subcategory/:reportId,:reportName', {
            templateUrl: './home.html'
          })
          .otherwise({
            redirectTo: '/view'
          });
    })
    // Register the translation namespaces you are going to use, and all the languages that are managed by the
    // dev team. You must always register locale 'en' since this is the default locale.
    // We define our controller
    .controller('HomeController', function($window, $timeout, $routeParams, $scope, $compile, $location, $interval,
                                           $filter, InternalReport, reportService, csrfService, orderByFilter) {
      var self = this;

      self.routeParams = $routeParams;
      self.pdfApi = {};

      self.collapsed = false;
      self.toggleCollapse = function() {
        self.collapsed = !self.collapsed;
      };

      /**
       * This method checks if the current device is fullcreen capable or not (for instance tablet aren't)
       * See http://www.sitepoint.com/use-html5-full-screen-api/
       * @returns {*}
       */
      var fullscreenCapable = function() {
        return document.fullscreenEnabled ||
            document.webkitFullscreenEnabled ||
            document.mozFullScreenEnabled ||
            document.msFullscreenEnabled;
      };

      var buildCategoryIdAndRoute = function() {
        if (angular.isDefined(self.routeParams.categoryId)) {
          self.currentCategoryId = parseInt(self.routeParams.categoryId);
          self.currentCategoryRoute = self.routeParams.categoryName;
        } else {
          if (angular.isDefined(self.formatedReports[0])) {
            self.currentCategoryId = self.formatedReports[0].id;
            self.currentCategoryRoute = self.formatedReports[0].name;
          }
        }
      };

      var _loadPdf = function(reports) {
        self.pdfUrl = {
          url: _environment.baseUrl + '/rest/internal-reporting/' + self.routeParams.reportId + '/file',
          httpHeaders: {}
        };
        self.pdfUrl.httpHeaders[csrfService.settings.csrfTokenHeader] = csrfService.token;
        self.pdfUrl.httpHeaders.Authorization = window.sgwtConnect.getAuthorizationHeader();
        var loadPdf = $interval(function() {
          if (angular.isDefined(self.pdfApi) && angular.isDefined(self.pdfApi.loadPdf)) {
            angular.forEach(reports, function(report) {
              if (report.id === self.routeParams.reportId) {
                self.report = report;
              }
            });
            self.pdfApi.loadPdf(self.pdfUrl);
            self.collapsed = true;
            $interval.cancel(loadPdf);
            if (!fullscreenCapable()) {
              self.toggleFullscreenAndCollapse();
            }
          }
        }, 100);
      };

      self.reports = InternalReport.query();

      self.reports.$promise.then(function(reports) {
        self.formatedReports = orderByFilter(reportService.getReportGroupByCategory(reports), 'name');

        buildCategoryIdAndRoute();

        if (angular.isDefined(self.routeParams.reportId)) {
          _loadPdf(reports);
        }
      });

      self.getSubCategoriesByCategoryId = function(id) {
        var category = null;
        angular.forEach(self.formatedReports, function(c) {
          if (c.id === id) {
            category = c;
          }
        });
        if (null !== category) {
          return category.subcategories;
        }
        return [];
      };

      self.isSubCategoryOpen = function(subcategory) {
        var toggled = false;
        angular.forEach(subcategory.reports, function(report) {
          if (report.id === self.routeParams.reportId) {
            toggled = true;
          }
        });
        return toggled;
      };

      self.escape = function(name) {
        return name.replace(/ |\.|:/g, '-').toLowerCase();
      };

      self.buildCategoryUrl = function(category) {
        return '#/view/' + category.id + ',' + self.escape(category.name);
      };

      self.buildReportUrl = function(subcategory, report) {
        return '#/view/' + self.currentCategoryId + ',' + self.escape(self.currentCategoryRoute) +
            '/' + self.escape(subcategory.name) + '/' + report.id + ',' + self.escape(report.name);
      };

      self.onPageChange = function() {
        angular.element('.pdf-root-container').animate({scrollTop: 0}, 0);
        if (!angular.element('.spinner').hasClass('stop')) {
          angular.element('.spinner').addClass('stop');
        }
      };

      self.onDocumentLoaded = function() {
        // Zoom in in order to improve readability
        $timeout(function() {
          if (angular.isDefined(self.report) && angular.isDefined(self.report.zoomLevel)) {
            for (var i = 0; i < self.report.zoomLevel; i++) {
              self.pdfApi.zoomIn();
            }
          }
        }, 10);
      };

      self.toggleFullscreenAndCollapse = function() {
        if (!fullscreenCapable()) {
          self.fullscreenOn = !self.fullscreenOn;
        }
        self.toggleCollapse();
      };

      self.zoomIn = function() {
        self.pdfApi.zoomIn();
      };

      self.zoomOut = function() {
        self.pdfApi.zoomOut();
      };

      self.getViewerClasses = function() {
        if (self.collapsed && !self.fullscreenOn) {
          return 'full-page col-xs-12';
        } else if (!self.collapsed && !self.fullscreenOn) {
          return 'col-md-9 hidden-xs hidden-sm';
        } else if (self.fullscreenOn) {
          return 'col-xs-12';
        }
        return '';
      };

      self.currentCategory = '';
      self.changeCategory = function(newCategory) {
        self.currentCategory = newCategory;
      };
      self.isCurrentCategory = function(category) {
        return self.currentCategory === category;
      };
    })
;
